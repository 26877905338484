// // src/components/Button.js
// import React from 'react';
// import './Button.css'; // Импортируем стили для кнопки

// function Button({ children, onClick, className }) {
//   return (
//     <button className={`button ${className || ''}`} onClick={onClick}>
//       {children}
//     </button>
//   );
// }

// export default Button;

// src/components/Button.js
import React from 'react';
import './Button.css'; // Убедитесь, что путь указан правильно
import classNames from 'classnames';

function Button({ children, onClick, className }) {
  return (
    // <button className={`button ${className || ''}`} onClick={onClick}>
    // <button className={`button ${className ? className.trim() : ''}`} onClick={onClick}>
    // <button className={`button ${className ? className : ''}`} onClick={onClick}>
    <button className={classNames('button', className)} onClick={onClick}>

      {children}
    </button>
  );
}

export default Button;