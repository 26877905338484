// components/OrderForm.js
import React, { useState } from 'react';
import axios from 'axios';
import './OrderForm.css';

function OrderForm({ product }) {
    const [formData, setFormData] = useState({
        customerName: '',
        customerEmail: '',
        startDate: '',
        endDate: ''
    });

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Получение CSRF-токена
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    // Установка CSRF-токена в заголовки по умолчанию
    axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');

    // Обработчик изменения полей формы
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Обработчик отправки формы
    const handleSubmit = (event) => {
        event.preventDefault();

        // Проверка корректности дат
        if (new Date(formData.endDate) <= new Date(formData.startDate)) {
            alert('Дата окончания должна быть позже даты начала аренды.');
            return;
        }

        setIsLoading(true);
    
    const formattedStartDate = new Date(formData.startDate).toISOString().split('T')[0];
    const formattedEndDate = new Date(formData.endDate).toISOString().split('T')[0];

        axios.post('/api/orders/', {
            product: product.id,  // Убедитесь, что используете 'product', а не 'product_id'
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            // start_date: formData.startDate,
            // end_date: formData.endDate,
            customer_name: formData.customerName,  // Используйте 'customer_name'
            customer_email: formData.customerEmail,  // Используйте 'customer_email'
          })
          
        .then(response => {
            setMessage('Заказ успешно создан!');
            // Сбросить форму
            setFormData({
                customerName: '',
                customerEmail: '',
                startDate: '',
                endDate: ''
            });
        })
        .catch(error => {
            setMessage('Ошибка при создании заказа. Пожалуйста, попробуйте снова.');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Заказ для {product.name}</h2>

            {message && <p>{message}</p>}

            <label htmlFor="customerName">Имя клиента:</label>
            <input
                id="customerName"
                name="customerName"
                type="text"
                value={formData.customerName}
                onChange={handleChange}
                required
            />

            <label htmlFor="customerEmail">Email клиента:</label>
            <input
                id="customerEmail"
                name="customerEmail"
                type="email"
                value={formData.customerEmail}
                onChange={handleChange}
                required
            />

            <label htmlFor="startDate">Дата начала аренды:</label>
            <input
                id="startDate"
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleChange}
                required
            />

            <label htmlFor="endDate">Дата окончания аренды:</label>
            <input
                id="endDate"
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleChange}
                required
            />

            <button type="submit" disabled={isLoading}>
                {isLoading ? 'Отправка...' : 'Оформить заказ'}
            </button>
        </form>
    );
}

export default OrderForm;
