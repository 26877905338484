// src/App.js
import React from 'react';
// import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProductsPage from './components/ProductsPage';
// import './styles.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* Главная страница */}
        <Route path="/products" element={<ProductsPage />} /> {/* Страница продуктов */}
      </Routes>
    </Router>
  );
}

export default App;
