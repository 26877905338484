// src/components/HomePage.js
// import React from 'react';
// import { Link } from 'react-router-dom';
// import Button from './Button';

// function HomePage() {
//   return (
//     <div>
//       <h1>Добро пожаловать на главную страницу!</h1>
//       <Link to="/products">
//         <Button>Перейти к продуктам</Button>
//       </Link>
//     </div>
//   );
// }

// export default HomePage;


import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';
// import Button from './Button';

function HomePage() {
  return (
    <div className="homepage-container">
      {/* Заголовок */}
      <header className="header">
        <h1>CableForce</h1>
        <h2>Аренда оборудования для прокладки кабеля</h2>
      </header>

      {/* Навигационная панель */}
      <nav className="navigation">
        <button>Главная</button>
        <button>Условия аренды</button>
        {/* <Link to="/products">
          <button>Парк оборудования</button>
        </Link> */}
        <Link to="/products" className="button-link">
          <button className="full-width-button">Парк оборудования</button>
        </Link>
        {/* <a href="/products" className="button-link">Парк оборудования</a> */}
        <button>Контакты</button>
        <input type="text" placeholder="поиск по сайту" className="search-input" />
      </nav>

      {/* Оборудование */}
      <section className="equipment-section">
        <div className="equipment-card">
          <div className="image-placeholder">[Image]</div>
          <h3>Кабельная лебедка</h3>
          <p>Для оптического кабеля</p>
        </div>

        <div className="equipment-card">
          <div className="image-placeholder">[Image]</div>
          <h3>Кабельный домкрат</h3>
          <p>Для барабанов до 5 тонн</p>
        </div>

        <div className="equipment-card">
          <div className="image-placeholder">[Image]</div>
          <h3>Кабельная лебедка</h3>
          <p>Для силового кабеля</p>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
